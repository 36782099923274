<template>
  <my-simple-layout class="tenant-register">
    <template>
      <h1 class="title">欢迎注册使用怀真 CMS, 请认真填写以下信息</h1>
      <hr style="width: 80%" />
      <div v-if="!successed" class="content">
        <el-form
          :model="formData"
          :rules="formRules"
          size="mini"
          ref="registerForm"
          label-width="100px"
          class="register-form"
          label-suffix=":"
        >
          <el-form-item label="名称" prop="name">
            <el-input type="text" v-model="formData.name" />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input type="text" v-model="formData.phone" />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="formData.password" />
          </el-form-item>
          <el-form-item label="确认密码" prop="password2" required>
            <el-input type="password" v-model="formData.password2" />
          </el-form-item>
          <el-form-item label="验证码" prop="imgVerifyCode">
            <el-input
              type="text"
              v-model="formData.imgVerifyCode"
              style="width: 60%"
            />
            <div class="img-verify-code">
              <img
                :src="`${serverUrl}/imgcaptcha?r=${imgCaptchaRandCode}`"
              />
              <i class="el-icon-refresh" @click="refreshImageVerifyCode" />
            </div>
          </el-form-item>
          <el-form-item label="手机校验码" prop="verifyCode">
            <el-input
              type="text"
              style="width: 60%"
              v-model="formData.verifyCode"
            />
            <el-button
              type="primary"
              plain
              style="float: right"
              :loading="waitingCaptcha > 0"
              @click="sendCaptcha"
            >
              {{
                waitingCaptcha > 0
                  ? `${waitingCaptcha}秒后重新发送`
                  : "发送验证码"
              }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="serviceAgreementCheck"
              >已阅读并同意以下协议</el-checkbox
            ><el-link type="primary" target="_blank" href="./user-protocol"
              >《使用协议》</el-link
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="register-btn" @click="doRegister"
              >注 册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="successed" class="content success-content">
        注册成功，请直接直接使用<span style="color: #1989fa;">{{ formData.phone }}</span>作为账号登录，正在<el-link
          href="/login"
          >跳转</el-link
        >登录页面...
      </div>
    </template>
  </my-simple-layout>
</template>

<script>
export default {
  name: "tenant-register",
  data() {
    return {
      formData: {},
      successed: false,
      imgCaptchaRandCode: new Date().getTime(),
      waitingCaptcha: 0,
      serviceAgreementCheck: false,
      formRules: {
        name: [
          { required: true, message: "给自己起个名字吧", trigger: "blur" },
          { min: 3, max: 8, message: "请输入3~8个字符", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { len: 11, message: "手机号码格式不正确", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "请输入6~18位的密码", trigger: "blur" },
        ],
        password2: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.formData.password) {
                callback(new Error("两次输入密码不一致!"));
              }
            },
            trigger: "blur",
          },
        ],
        imgVerifyCode: [
          {
            required: true,
            message: "请输入右侧图片中的字符",
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    serverUrl: {
      get() {
        return this.$http.getServerUrl();
      }
    }
  },
  methods: {
    async doRegister() {
      if (!this.serviceAgreementCheck) {
        this.$message.warning("请先仔细阅读并同意服务协议");
        return;
      }
      try {
        this.$refs.registerForm.validate();
      } catch (e) {
        console.log(e);
        return;
      }

      this.$http
        .doApi("fw-tenant-do-register", {
          name: this.formData.name,
          phone: this.formData.phone,
          verifyCode: this.formData.verifyCode,
          password: this.formData.password,
        })
        .then(() => {
          this.successed = true;
          setTimeout(() => {
            this.$router.replace("/login");
          }, 3000);
        })
        .catch((e) => console.log(e));
    },
    sendCaptcha() {
      let props = ["phone", "imgVerifyCode"];
      let count = 0;
      this.$refs.registerForm.validateField(props, (msg) => {
        if (!msg) {
          count++;
        }

        if (count == props.length) {
          this.$http
            .doApi("fw-tenant-register-sendCaptcha", {
              phone: this.formData.phone,
              imgVerifyCode: this.formData.imgVerifyCode,
              imgVerifyKey: this.imgCaptchaRandCode,
            })
            .then(() => {
              this.waitingCaptcha = 60;
              let intervalId = setInterval(() => {
                this.waitingCaptcha--;
                if (this.waitingCaptcha <= 0) {
                  clearInterval(intervalId);
                }
              }, 1000);
              this.$message.success("发送成功");
              this.refreshImageVerifyCode();
            })
            .catch(() => this.refreshImageVerifyCode());
        }
      });
    },
    refreshImageVerifyCode() {
      // 刷新图片验证码
      this.imgCaptchaRandCode = new Date().getTime();
    },
  },
  components: {
    "my-simple-layout": () => import("@/components/SimpleLayout"),
  },
};
</script>

<style lang="less" scoped>
.tenant-register {
  .title {
    padding-left: 15%;
    font-size: 2rem;
    margin-top: 0px;
  }

  .success-content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      font-size: 16px;
      vertical-align: unset;
      color: #1989fa;
    }
  }

  .content {
    padding-top: 3%;
    height: 80%;
    .register-form {
      width: 30%;
      margin: 0px auto;

      .img-verify-code {
        width: 40%;
        height: 28px;
        float: right;
        text-align: center;

        img {
          vertical-align: middle;
          width: 100px;
          height: 100%;
          margin-right: 10px;
        }
      }

      .register-btn {
        width: 100%;
        word-spacing: 2rem;
      }
    }
  }
}
</style>